import React from 'react';
import { FaChartPie, FaNetworkWired, FaUserFriends, FaUserSecret } from "react-icons/fa";

import Alert from 'react-bootstrap/Alert';
// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import './Card.css';
import './Dashboard.css';
import './SideMenu.css';
import { getProfiles } from '../../api/fraudProfiles';



export default class FraudProfileListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fraudProfiles: [],
      loading: true,
      error: false,
    };

    this.renderProfileRows = this.renderProfileRows.bind(this);
    this.renderDashboardSection = this.renderDashboardSection.bind(this);
    this.renderProfileRows = this.renderProfileRows.bind(this);
  }

  async componentDidMount() {

    const fraudProfileData = await getProfiles();
    if (fraudProfileData.status === 200) {
      this.setState({fraudProfiles: fraudProfileData.data['results'], loading: false})
    } else {
      this.setState({error: true, loading: false});
    }
  }

  renderProfileRows() {
    if (this.state.loading) {
      return (
        <tr style={{paddingTop: '4rem'}}>
          <td colSpan={6} align={'center'}>
            <Spinner animation="border" />
          </td>
        </tr>
      );
    } else {
      return this.state.fraudProfiles.map(profile => {
        let users = null;
        let ipAddresses = null;
        try {
          users = profile.users.map(user => user.email);
          ipAddresses = profile.ip_records.map(record => record.ip_record.ip_address);
          ipAddresses = [...new Set(ipAddresses)];
        } catch { /* ignore, we'll handle it below */ }
        const year = profile.created_at.substring(0,10);
        const time = profile.created_at.substring(11,16);
        const created = `${year} ${time}`;
        return (
          <Row style={{padding: '1rem'}} key={profile.id}>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={1}>{profile.id}</Col>
                    <Col md={2}>{created}</Col>
                    <Col md={3}>{users ? users.join(', ') : ''}</Col>
                    <Col md={4}>{ipAddresses ? ipAddresses.join(', ') : ''}</Col>
                    {/* <Col md={1}><Button variant="info">View</Button></Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      });
    }
  }

  renderDashboardSection() {
    if (this.state.error) {
      return <Alert variant='danger'>Could not load data</Alert>
    }

    return (
      <Row style={{paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Col md={12}>
          <Card>
            <Card.Header>Profiles Constructed</Card.Header>
            <Card.Body>
              <Row>
                <Col md={1}>ID</Col>
                <Col md={2}>Created At</Col>
                <Col md={3}>Users</Col>
                <Col md={3}>IP Addresses</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    return (
    <>
      <Container fluid>
        <Row style={{paddingTop: '1rem'}}>
          <Col className='menu-panel' md={1}>
            <div className="menu-header">
              <hr/>
              <p className="menu-header-text">HEIMDALL</p>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/dashboard">
                <div style={{display: 'inline-block'}}><FaChartPie size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Dashboard</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaUserSecret  size={28} color={'#f98125'}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text active-element">Profiles</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/users">
                <div style={{display: 'inline-block'}}><FaUserFriends  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">People</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaNetworkWired  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">IP Data</p></div>
              </a>
              <hr/>
            </div>
          </Col>
          <Col md={11}>
            { this.renderDashboardSection() }
            { this.renderProfileRows() }
          </Col>
        </Row>
        </Container>
      </>
    );
  }
};