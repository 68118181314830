import React from 'react';
import Graph from "react-graph-vis";
import { FaChartPie, FaNetworkWired, FaUserFriends, FaUserSecret } from "react-icons/fa";

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './Card.css';
import './Dashboard.css';
import './SideMenu.css';

import { getFraudUsers, getUserNetworkData } from '../../api/users';


export default class UserListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      profiles: [],
      loadingRows: true,
      loadingRelations: true,
      error: false,
    };

    this.renderDashboardSection = this.renderDashboardSection.bind(this);
    this.renderUserTableRows = this.renderUserTableRows.bind(this);
    this.renderUserRelations = this.renderUserRelations.bind(this);
  }

  async componentDidMount() {

    let networkData = await getUserNetworkData();
    if (networkData.status === 200) {
      let usersData = await getFraudUsers();
      if (usersData.status === 200) {
        this.setState({
          users: usersData.data['results'],
          loadingRows: false,
          profiles: networkData.data,
          loadingRelations: false,
        });
      } else {
        this.setState({
          error: true,
          loadingRows: false,
          loadingRelations: false
        });
      }
    } else {
      this.setState({
        error: true,
        loadingRows: false,
        loadingRelations: false
      });
    }

  }

  renderUserTableRows() {

    if (this.state.loadingRows) {
      return <div align="middle"><Spinner animation="border" /></div>
    } else {
      let rows = [];
      for (let i = 0; i < this.state.users.length; i++) {
        let user = this.state.users[i];
        rows.push(
          <div>
            <div className="table-link" key={`user_${user.id}`}>
              <Row className='table-row'>
                <Col md={1} className="user-table-cell">{user.id}</Col>
                <Col md={2} className="user-table-cell">{user.email}</Col>
                <Col md={2} className="user-table-cell">{user.first_name}</Col>
                <Col md={2} className="user-table-cell">{user.last_name}</Col>
                <Col md={1} className="user-table-cell">{user.account_type}</Col>
                <Col md={1} className="user-table-cell">{user.profile_count}</Col>
                <Col md={2} className="user-table-cell">{user.personal_data_count}</Col>
                <Col md={1} className="user-table-cell">{user.ip_record_count}</Col>
              </Row>
            </div>
            <hr/>
          </div>
        );
      }
      return rows;
    }
  }

  renderUserRelations() {

    const options = {
      physics: {
        stabilization: false
      },
      edges: {
        smooth: true,
        color: '#1ba2d8',
        width: 0.5,
        arrows: {
          to: {
            enabled: false,
            scaleFactor: 0.5
          }
        }
      },
      nodes: {
        shape: 'dot',
        size: 10,
        color: '#1ba2d8',
      },
      height: "600px"
    };

    const events = {
      select: function(event) {
        console.log(event);
      }
    };
    if (this.state.loadingRelations) {
      return <div align="middle"><Spinner animation="border" /></div>
    } else {
      let nodes = [];
      let edges = [];
      let userIDs = [];
      for (let i = 0; i < this.state.profiles.length; i++) {
        let profile = this.state.profiles[i];
        let profileID = `profile_${profile.id}`;
        nodes.push({id: profileID, label: profile.id, color: '#f98125'});
        for (let j = 0; j < profile.users.length; j++) {
          let user = profile.users[j];
          let userID = `user_${user.id}`;
          let combinedID = `user_${user.id}_${profileID}`;
          for (let k = 0; k < userIDs.length; k++) {
            if (userIDs[k].includes(userID)) {
              edges.push({
                from: userIDs[k],
                to: combinedID,
                color: '#f98125',
                width: 2,
              });
            }
          }
          userIDs.push(combinedID);
          nodes.push({
            id: combinedID,
            label: user.email,
            title: `${user.first_name} ${user.last_name}`,
            font: {
              size: 10,
              color: "#efeeed",
              strokeColor: "#efeeed",
            },
          });
          edges.push({from: profileID, to: combinedID});
        }
      }
      let graph = {
        nodes: nodes,
        edges: edges,
      };
      return (
        <Graph graph={graph} options={options} events={events} />
      );
    }
  }

  renderDashboardSection() {
    if (this.state.error) {
      return <Alert variant='danger'>Could not load data</Alert>
    }
    return (
      <>
        <Row style={{paddingLeft: '1rem',paddingRight: '1rem'}}>
          <Tabs defaultActiveKey="listview" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="listview" title="List View">
              <Col md={12}>
                <Card>
                  <Card.Header>User Accounts Detected</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md={1}>ID</Col>
                      <Col md={2}>Email</Col>
                      <Col md={2}>First Name</Col>
                      <Col md={2}>Last Name</Col>
                      <Col md={1}>Type</Col>
                      <Col md={1}>Profiles</Col>
                      <Col md={2}>Personal Data Records</Col>
                      <Col md={1}>IP Records</Col>
                    </Row>
                    <br/>
                    {this.renderUserTableRows()}
                  </Card.Body>
                </Card>
              </Col>
            </Tab>
            <Tab eventKey="networkview" title="Network View">
              <Col md={12}>
                <Card>
                  <Card.Header>User Relations</Card.Header>
                  <Card.Body>
                    {this.renderUserRelations()}
                  </Card.Body>
                </Card>
              </Col>
            </Tab>
          </Tabs>
        </Row>
      </>
    );
    
  }

  render() {
    return (
      <>
      <Container fluid>
        <Row style={{paddingTop: '1rem'}}>
          <Col className='menu-panel' md={1}>
            <div className="menu-header">
              <hr/>
              <p className="menu-header-text">HEIMDALL</p>
              <hr/>
            </div>
            <div className="menu-element">
              <a href='/dashboard'>
                <div style={{display: 'inline-block'}}><FaChartPie size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Dashboard</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaUserSecret  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Profiles</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
                <div style={{display: 'inline-block'}}><FaUserFriends  size={28}  color={'#f98125'}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text active-element">People</p></div>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/iprecords">
                <div style={{display: 'inline-block'}}><FaNetworkWired  size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">IP Data</p></div>
              </a>
              <hr/>
            </div>
          </Col>
          <Col md={11}>
            {this.renderDashboardSection()}
          </Col>
        </Row>
        </Container>
      </>
    );
  }
}
