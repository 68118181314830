
export async function getFraudUsers() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);

  let response = await fetch(
    process.env.REACT_APP_FRAUD_USERS_URL,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }
  );
  let data = await response.json();
  return {data: data, status: response.status};
}


export async function getUserNetworkData() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  let response = await fetch(
    `${process.env.REACT_APP_FRAUD_USERS_URL}network_graph/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }
  );
  let data = await response.json();
  return {data: data, status: response.status};
}