import React from 'react';
import './App.css';
import './css/Base.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardPage from './components/pages/Dashboard';
import LoginPage from './components/pages/Login';
import ProfileList from './components/pages/ProfileList';
import IPAddressListPage from './components/pages/IPAddresses';
import UserListPage from './components/pages/UserList';
import useToken from './components/common/useToken.js';


function App() {
  const { token, setToken } = useToken();
  if(!token) {
    return <LoginPage setToken={setToken} />
  }
  return (
    <div className="wrapper" style={{backgroundColor: '#111721', height: '100vh'}}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact={true}>
            <LoginPage setToken={setToken}/>
          </Route>
          <Route path="/" exact={true}>
            <DashboardPage/>
          </Route>
          <Route path="/dashboard" exact={true}>
            <DashboardPage/>
          </Route>
          <Route path="/profiles" exact={true}>
            <ProfileList/>
          </Route>
          <Route path="/iprecords" exact={true}>
            <IPAddressListPage/>
          </Route>
          <Route path="/users" exact={true}>
            <UserListPage/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
