import React from 'react';

import { FaChartPie, FaNetworkWired, FaUserFriends, FaUserSecret } from "react-icons/fa";

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { scaleLinear } from "d3-scale";
import {
  Annotation,
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";
import './Card.css';
import './Dashboard.css';
import './SideMenu.css';
import ReactResizeDetector from 'react-resize-detector';
import { getIPRecords } from '../../api/ipRecords';

export default class IPAddressListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ipAddresses: [],
      loading: true,
      error: false,
    };

    this.renderIPRows = this.renderIPRows.bind(this);
    this.renderHeatmap = this.renderHeatmap.bind(this);
    this.renderDashboardSection = this.renderDashboardSection.bind(this);
  }

  async componentDidMount () {
    let ipData = await getIPRecords();
    if (ipData.status === 200) {
      let checkList = []
      let ipAddresses = [];
      const results = ipData.data.results;
      for (let i = 0; i < results.length; i++) {
        try {
          const check = `${results[i].ip_record.ip_address}_${results[i].user}`;
          if (checkList.indexOf(check === -1)) { // only add if this user/ip combination doesn't already exist
            ipAddresses.push(results[i]);
            checkList.push(check);
          } else {
            console.log(`excluding ${check}`)
          }
        } catch { /* ignore - it was strange data anyway */}
      }
      this.setState({ipAddresses, loading: false});
    } else {
      this.setState({error: true, loading: false});
    }
  }

  renderHeatmap() {

    const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

    const colorScale = scaleLinear()
    .domain([0, 3])
    .range(["#ffedea", "#f98125"]);


    let data = {};
    for (let i = 0; i < this.state.ipAddresses.length; i++) {
      let country = this.state.ipAddresses[i]['ip_record']['country'];
      if (country in data) {
        data[country] += 1;
      } else {
        data[country] = 1;
      }
    }
    let mapData = [];
    let unplaced = 0;
    for (let key in data) {
      mapData.push({country: key, count: data[key]});
      if (key === null || key === "null") {
        unplaced += data[key];
      }
    }

    return <ReactResizeDetector handleWidth handleHeight>{
      ({width, height}) => <ComposableMap projectionConfig={{rotate: [-10, 0, 0], scale: 147}} height={height}>
        <Sphere stroke="#1ba2d8" strokeWidth={0.5} />
        <Graticule stroke="#1ba2d8" strokeWidth={0.5} />
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = mapData.find((s) => s.country.toLowerCase() === geo.properties.NAME.toLowerCase() || s.country.toLowerCase() === geo.properties.NAME_LONG.toLowerCase());
              return (
                <Geography
                  style={{stroke: '#1ba2d8', strokeWidth: 2}}
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d["count"]) : "#888888"}
                />
              );
            })
          }
        </Geographies>
        <Annotation
          subject={[2.3522, 48.8566]}
          dx={-130}
          dy={300}
          connectorProps={{
            strokeWidth: 0,
          }}
        >
          <text style={{ transform: 'translateX(2px)' }} textAnchor="end" alignmentBaseline="middle" fill="#f98125">
            {`${unplaced} IP addresses could not be placed.`}
          </text>
        </Annotation>
      </ComposableMap>
    }
    </ReactResizeDetector>

  }

  renderIPRows() {
    if (this.state.loading) {
      return <div align="middle"><Spinner animation="border" /></div>
    } else {
      let rows = [];
      for (let i = 0; i < this.state.ipAddresses.length; i++) {
        let currentRecord = this.state.ipAddresses[i];
        let currentIP = currentRecord['ip_record'];
        rows.push(
          <div>
            <div className="table-link">
              <Row className='table-row'>
                <Col md={3} className="user-table-cell">{currentRecord['id']}</Col>
                <Col md={3} className="user-table-cell">{currentIP['email']}</Col>
                <Col md={3} className="user-table-cell">{currentIP['ip_address']}</Col>
                <Col md={3} className="user-table-cell">
                  <p>{currentIP['city']}</p>
                  <p>{currentIP['region']}</p>
                  <p>{currentIP['country']}</p>
                  <p>{currentIP['continent']}</p>
                </Col>
              </Row>
            </div>
            <hr/>
          </div>
        );
      }
      return rows;
    }
  }

  renderDashboardSection() {
    if (this.state.error) {
      return <Alert variant='danger'>Could not load data</Alert>
    }
    return (
      <Row style={{paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Col md={4}>
          <Card>
            <Card.Header>IP Addresses Detected</Card.Header>
            <Card.Body style={{maxHeight: '71vh', overflowY: 'auto'}}>
              <Row>
                <Col md={3}>ID</Col>
                <Col md={3}>User</Col>
                <Col md={3}>IP</Col>
                <Col md={3}>Location</Col>
              </Row>
              <br/>
              {this.renderIPRows()}
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Header>Geography</Card.Header>
            <Card.Body style={{maxHeight: '71vh'}}>
              {this.renderHeatmap()}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  render() {
    return (
    <>
      <Container fluid>
        <Row style={{paddingTop: '1rem'}}>
          <Col className='menu-panel' md={1}>
            <div className="menu-header">
              <hr/>
              <p className="menu-header-text">HEIMDALL</p>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/dashboard">
                <div style={{display: 'inline-block'}}><FaChartPie size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Dashboard</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/profiles">
                <div style={{display: 'inline-block'}}><FaUserSecret size={28} /></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">Profiles</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/users">
                <div style={{display: 'inline-block'}}><FaUserFriends size={28}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text">People</p></div>
              </a>
              <hr/>
            </div>
            <div className="menu-element">
              <a href="/iprecords">
                <div style={{display: 'inline-block'}}><FaNetworkWired size={28} color={'#f98125'}/></div>
                <div style={{display: 'inline-block'}}><p className="menu-element-text active-element">IP Data</p></div>
              </a>
              <hr/>
            </div>
          </Col>
          <Col md={11}>
            { this.renderDashboardSection() }
          </Col>
        </Row>
        </Container>
      </>
    );
  }
};