
export async function getIPRecords() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);

  let response = await fetch(
    process.env.REACT_APP_IP_RECORDS_URL,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }
  );
  let data = await response.json();
  return {data: data, status: response.status};
}

export async function getGlobePins() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);

  let response = await fetch(
    `${process.env.REACT_APP_IP_RECORDS_URL}get_globe_pins/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${userToken.token}`
      }
    }
  );
  let data = await response.json();
  return {data: data, status: response.status};
}